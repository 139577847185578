import React, { useCallback, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { Section } from 'components/Layout'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import Prev from 'images/arrow-circle-left-blue.svg'
import Next from 'images/arrow-circle-right-blue.svg'
import StyledTag from '../Shared/StyledTag'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const StyledNavigation = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
`

const TitleTag = styled(StyledTag)`
  margin: 20px auto;
`

const Title = styled.h2`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  color: ${({ theme }) => theme.palette.primary.main};
`

const StyledImage = styled(GatsbyImage)`
  margin: 20px auto;
  border-radius: 128px;
  width: 128px;
`

const Quote = styled.p`
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
`

const Name = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: center;
`

const StyledSwiper = styled(Swiper)`
  padding-bottom: 20px !important;
`

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`
/*
const Images = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  align-items: center;
  gap: 12px;
  ${media.sm`
    gap: 80px;
  `}
`
*/
const TestimonialsSlice = () => {
  const sliderRef = useRef(null)
  const theme = useTheme()

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slideNext()
  }, [])

  const data = useStaticQuery(graphql`
    query TestimonialsABTestingQuery {
      allContentfulTestimonial(
        sort: { fields: customerDescription, order: ASC }
      ) {
        nodes {
          customerWebsiteUrl
          customerDescription
          testimonial {
            testimonial
          }
          customerImage {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: NONE)
            description
          }
        }
      }
      capterra: file(relativePath: { eq: "capterra-rating.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 65
            width: 200
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      aws: file(relativePath: { eq: "aws-qualified.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 65
            width: 100
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      getApp: file(relativePath: { eq: "get-app-rating.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 65
            width: 100
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const testimonials = data.allContentfulTestimonial.nodes
  const sliceContent = {
    title: "Business Leaders Trust our AWS SES Email Template Manager",
    tag: 'VOICE OF USERS',
  }
  
  /* USE LATER
  const batches = () => (
      <Images>
        <div>
          <GatsbyImage image={getImage(data.capterra)} />
        </div>
        <div>
          <GatsbyImage image={getImage(data.aws)} />
        </div>
        <div>
          <GatsbyImage image={getImage(data.getApp)} />
        </div>
      </Images>
    )
    */
  return (
    <Section bgStyle="white">
      <TitleTag>{sliceContent.tag}</TitleTag>
      <Title>{sliceContent.title}</Title>
      <StyledSwiper
        ref={sliderRef}
        slidesPerView={1}
        spaceBetween={20}
        loop
        pagination={{ clickable: true }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        style={{ '--swiper-pagination-color': theme.palette.primary.main }}
      >
        {testimonials.map(testimonial => (
          <SwiperSlide>
            <StyledImage
              image={getImage(testimonial.customerImage)}
              alt={testimonial.customerImage.description}
            />
            <Quote>{testimonial.testimonial.testimonial}</Quote>
            <StyledLink
              href={testimonial.customerWebsiteUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Name>{testimonial.customerDescription}</Name>
            </StyledLink>
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <StyledNavigation>
        <Button type="button" className="prev-arrow" aria-label="Go to previous Testimonials Item" onClick={handlePrev}>
          <Prev />
        </Button>
        <Button type="button" className="next-arrow" aria-label="Go to next Testimonials Item" onClick={handleNext}>
          <Next />
        </Button>
      </StyledNavigation>
      
    </Section>
  )
}

export default TestimonialsSlice
