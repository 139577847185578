import React from "react";
import { media } from 'styled-bootstrap-grid';
import styled from "styled-components";
import ReusableContentBlocks from 'images/icons/reusable-content-blocks.svg';
import StockImages from 'images/icons/stock-images.svg';
import TemplateHistory from 'images/icons/template-history.svg';
import ResponsiveDesign from 'images/icons/responsive-design.svg';
import AdvancedEmailPersonalisation from 'images/icons/advanced-email-personalisation.svg';
import SendTemplatedEmails from 'images/icons/send-templated-emails.svg';
import SortFilterSearch from 'images/icons/sort-filter-search.svg';

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px;
  ${media.lg`
    padding: 0px;
  `}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 80px;
  padding: 80px 0px;

  ${media.lg`
    grid-template-columns: repeat(3, 1fr); // Three items per row on large screens
  `}
`;

const FeatureTitle = styled.h2`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-bottom: 0px;
  margin-top: 40px;
  `;
  
  const FeatureDescription = styled.p`
  font-size: 18px;
  color: white;
`;

const FeaturesEditorFeaturesGrid = () => {

    const features = [
        {
            title: 'Reusable content blocks',
            description: 'Create reusable email blocks to speed-up the email building process.',
            Icon: ReusableContentBlocks,
        },
        {
            title: 'Stock images & Media library',
            description: 'Get access to a library of various media to kickstart the creation of your emails.',
            Icon: StockImages,
        },
        {
            title: 'Template History',
            description: "View and track your templates' change and publishing history. Restore as needed.",
            Icon: TemplateHistory,
        },
        {
            title: 'Responsive design review',
            description: 'Specify platform-specify content, then review how your email will render across 90+ devices.',
            Icon: ResponsiveDesign,
        },
        {
            title: 'Advanced Email Personalisation',
            description: 'Semplates supports handlebars syntax used by AWS SES. It gives you the power to easily construct, validate and test email personalization.',
            Icon: AdvancedEmailPersonalisation,
        },
        {
            title: 'Send your templated email to your inbox',
            description: 'Test if before you send it to discover how your target group will see your template.',
            Icon: SendTemplatedEmails,
        },
        {
            title: 'Sort, filter, and search all your SES templates',
            description: 'Easily manage hundreds of SES templates. Find anything related to your templates in a matter of seconds.',
            Icon: SortFilterSearch,
        },
    ];4

    return (
        <Grid>
            {features.map(({ title, description, Icon }) => (
                <FeatureContainer key={title}>
                    <Icon/>
                    <FeatureTitle>{title}</FeatureTitle>
                    <FeatureDescription>{description}</FeatureDescription>
                </FeatureContainer>
            ))}
        </Grid>
    );
};

export default FeaturesEditorFeaturesGrid;

